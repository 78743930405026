import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DefectedResults from "./DefectedResults";
import useGetSelectedData from "./useGetSelectedData";
import DefectedResultsLoader from "./DefectedResultsLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewDefectUploadedDocument from "./ViewDefectUploadedDocument";
import UpdateModal from "./UpdateModal";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faDownload } from "@fortawesome/free-solid-svg-icons";
import EfilingConfirmationModal from "./EfilingConfirmationModal";
import axios from "axios";
const { REACT_APP_API_KEY, REACT_APP_BASE_URL } = process.env;

const DEFAULT_PAGE_LEVEL_DHC = {
  "Blurred Page": "PAGE IS BLURED AND ",
  "Missing Page Number":
    "PAGE NUMBERING BE DONE IN CONTINUOUS WITHOUT ALPHABETICAL AND ALPHANUMERIC PAGE NUMBERING/DOUBLE PAGE NO. BE DELETED BY PROPER FLUIDING.",
  "Signature Absent":
    "PETITION/ APPLICATIONS/ MOP/ INDEX/ POWER OF ATTORNEY BE SIGNED AND DATED BY PETITIONERS AND ADVOCATE",
  "NOT in OCR format":
    "PLAINT/SUIT/PETITION BE TYPED IN DOUBLE SPACING WITH 14 FONT SIZE.(IN CASE OF E-FILING PLAINT/PETITION BE TYPED IN 1.5 LINE SPACING WITH 14 FONT SIZE IN PDF/OCR FORMAT) CH-III, R-1(II) E-FILING GUIDELINES -2018",
  Hindi:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Assamese:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Bengali:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Bodo: "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Dogri:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Gujarati:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Kannada:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Kashmiri:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Konkani:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Maithili:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Malayalam:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Manipuri:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Marathi:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Nepali:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Odia: "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Punjabi:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Sanskrit:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Santali:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Sindhi:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Tamil:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Telugu:
    "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
  Urdu: "ENGLISH TRANSLATION OF VERNACULAR DOCUMENTS BE FILED CH-III, R-1(I) -5 OF DHC(OS) RULES 2018 -2018",
};

const DEFAULT_DOC_LEVEL_DEFECTS_DHC = {
  "Bookmarks absent":
    "PROPER BOOKMARKING BE DONE ALONG WITH THE DESCRIPTION OF THE ANNEXURES AND PAGE NO AS GIVEN IN THE INDEX",
  "Synopsis does not exist":
    "LIST OF DATES/BRIEF SYNOPSIS SHALL ACCOMPANY SUIT/PLAINT/PETITION BEFORE MEMO OF PARTIES. ORDER DT. 9.11.2016 IN CS (COMM) 1267/ 2016 DIRECTION NO. 8 ISSUED U/S 18 OF COMMERCIAL COURTS ACT, 2015 -2018",
  "Synopsis and list of dates has more than 5 pages":
    "LIST OF DATES AND SYNOPSIS SHOULD NOT BE MORE THAN FIVE PAGES",
  "Vakalatnama Not Present":
    "VAKALATNAMA BE FILED / DATED AND SIGNED BY THE COUNSEL AND ALL PETITIONERS. EACH ADVOCATE MUST MENTION THEIR NAME/ ADDRESS/ ENROLMENT NO. MOBILE NUMBER IN VAKALATNAMA. TITLE ON THE VAKALATNAMA BE CHECKED. WELFARE STAMP BE AFFIXED. SIGNATURE OF THE CLIENT BE IDENTIFIED.",
  "Memo of Parties Absent":
    "LIST OF DATES/BRIEF SYNOPSIS SHALL ACCOMPANY SUIT/PLAINT/PETITION BEFORE MEMO OF PARTIES. ORDER DT. 9.11.2016 IN CS (COMM) 1267/ 2016 DIRECTION NO. 8 ISSUED U/S 18 OF COMMERCIAL COURTS ACT, 2015 -2018",
  "Proof of service missing":
    "SERVICE BE MADE TO THEIR NOMINATED COUNSEL PERSONALLY / TRACKING REPORT / DELIVERY REPORT OF SPEED POST / COURIER BE ATTACHED",
  "Description of Annexures absent":
    "BRIEF DESCRIPTION OF ANNEXURES BE GIVEN IN THE BOOKMARKING",
  "Court Fees Missing": "COURT FEE IS SHORT OR MISSING",
  "Affidavit Absent": "",
  "Impugned Order NOT mentioned in Prayer":
    "Order against which petition/application is filed be mentioned in prayer clause as well as in heading and the impugned order also be filed.",
};

const docLevelDefectCodesDHC = {
  "Bookmarks absent": "348",
  "Synopsis does not exist": "282",
  "Synopsis and list of dates has more than 5 pages": "363",
  "Vakalatnama Not Present": "237",
  "Memo of Parties Absent": "282",
  "Proof of service missing": "203",
  "Description of Annexures absent": "353",
  "Court Fees Missing": "352",
  "Affidavit Absent": "",
  "Impugned Order NOT mentioned in Prayer": "208",
};

const pageLevelDefectsCodeDHC = {
  "Blurred Page": "N/A",
  "Missing Page Number": "219",
  "Signature Absent": "210",
  "NOT in OCR format": "256",
  Hindi: "221",
  Assamese: "221",
  Bengali: "221",
  Bodo: "221",
  Dogri: "221",
  Gujarati: "221",
  Kannada: "221",
  Kashmiri: "221",
  Konkani: "221",
  Maithili: "221",
  Malayalam: "221",
  Manipuri: "221",
  Marathi: "221",
  Nepali: "221",
  Odia: "221",
  Punjabi: "221",
  Sanskrit: "221",
  Santali: "221",
  Sindhi: "221",
  Tamil: "221",
  Telugu: "221",
  Urdu: "221",
};

export const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY");
};
const VERNACULAR_LANGUAGE = [
  "Hindi",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];
const PAGE_LEVEL_DEFECT_STRING_DHC = [
  "Blurred Page",
  "Missing Page Number",
  "Signature Absent",
  "Hindi",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
  "NOT in OCR format",
];

const defectStrings = [
  "SLP format is incorrect",
  "Findings of high court NOT mentioned in SLP",
  "Drawn by NOT mentioned",
  "Certificate NOT filed in support of SLP by AoR",
  "Date of filing NOT mentioned",
  "List of Dates Absent",
  "Office report on Limitation Absent",
  "Page number of annexures NOT mentioned in the list of dates",
  "Description of annexures NOT mentioned in the list of dates",
  "Description of annexures NOT mentioned in the index",
  "Affidavit NOT filed",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent",
  "Deponent capacity Not Appicable",
  "Blanks NOT filed in affidavit",
  "Memo of parties NOT filed",
  "Listing Proforma NOT filed properly",
  "Vakalatnama NOT filed properly",
  "Memo of Appearance NOT filed properly",
  "Defect in Non-mentioning of days of delay",
  "All order dates NOT present in list of dates and events",
  "All Case numbers NOT matched between annexures and index",
  "All annexure dates NOT matched index dates",
  "All annexure descriptions NOT matched index descriptions",
  "Application for exemption from filing an official translation mentioned but NOT present",
];

const documentLevelNyaayDefectForDHC = [
  "Bookmarks absent",
  "Synopsis does not exist",
  "Synopsis and list of dates has more than 5 pages",
  "Vakalatnama Not Present",
  "Memo of Parties Absent",
  "Proof of service missing",
  "Description of Annexures absent",
  "Court Fees Missing",
  "Affidavit Absent",
  "Impugned Order NOT mentioned in Prayer",
];

const DEFAULT_DOC_LEVEL = {
  "SLP format is incorrect":
    "Non-filing of SLP(Civil) in Form No.28 with certificate as per Notification dated 17.6.1997",
  "Findings of high court NOT mentioned in SLP":
    "Non-mentioning of the findings of the High Court/ Courts below in the list of dates and para 1 of SLP.",
  "Drawn by NOT mentioned": "Non-mentioning of date of filing/ drawn by.",
  "Certificate NOT filed in support of SLP by AoR":
    "Non-filing of certificate by AoR in support of SLP.",
  "Date of filing NOT mentioned": "Non-mentioning of date of filing/ drawn by.",
  "List of Dates Absent": "Non-filing of brief list of dates/ events.",
  "Office report on Limitation Absent":
    "Non-filing of office report on limitation with cause title on Green/ White sheet.",
  "Page number of annexures NOT mentioned in the list of dates":
    "Non-mentioning of page numbers of annexures in the list of dates/ petition.",
  "Description of annexures NOT mentioned in the list of dates":
    "Incorrect mentioning of description of annexures in list of dates/ petition.",
  "Description of annexures NOT mentioned in the index":
    "Incorrect mentioning of description of annexures in Index.",
  "Affidavit NOT filed": "Non-filing of affidavit properly executed.",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent":
    "Non-filing of affidavit containing the statement that the facts stated in the petition are true to the knowledge, information and belief of the deponent.",
  "Deponent capacity Not Appicable":
    "Non-disclosure of deponent’s capacity [in case matter is filed on behalf of or by organisation/ company/ pairokar].",
  "Blanks NOT filed in affidavit": "Blanks in the affidavit.",
  "Memo of parties NOT filed":
    "Non-filing of Memo of Parties, as detailed cause title not given in the impugned judgment.",
  "Listing Proforma NOT filed properly":
    "Non-inclusion of complete listing proforma filled in, signed in the paper-books.",
  "Vakalatnama NOT filed properly":
    "Improper execution of Vakalatnama/Memo of Appearance.",
  "Memo of Appearance NOT filed properly":
    "Improper execution of Vakalatnama/Memo of Appearance.",
  "Defect in Non-mentioning of days of delay":
    "Non-mentioning of the number of days of delay .",
  "All order dates NOT present in list of dates and events":
    "All order dates NOT present in list of dates and events",
  "All Case numbers NOT matched between annexures and index":
    "Case No.(s) of annexures —–not given.",
  "All annexure dates NOT matched index dates":
    "Date(s) of annexures….. do not tally.",
  "All annexure descriptions NOT matched index descriptions":
    "Incorrect mentioning of description of annexures in Index.",
  "Application for exemption from filing an official translation mentioned but NOT present":
    "Non-filing of translation of vernacular document(s)",
};

const DEFAULT_PAGE_LEVEL = {
  "Blurred Page":
    "PAGE NO.............NOTCLEAR/ LEGIBLE/ SMALLFONT/ DIM/ MISSING.",
  "Missing Page Number":
    "Page Nos. not indicated on the right side of top of pages",
  "Signature Absent":
    "Non-bearing of signature of the counsel/ party-in-person in petition/ application/ certificate",
  Hindi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Assamese: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Bengali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Bodo: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Dogri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Gujarati: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Kannada: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Kashmiri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Konkani: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Maithili: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Malayalam: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Manipuri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Marathi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Nepali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Odia: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Punjabi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Sanskrit: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Santali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Sindhi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Tamil: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Telugu: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Urdu: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
};
const defectCodes = {
  "Listing Proforma NOT filed properly": "24.2",
  "Vakalatnama NOT filed properly": "8.1",
  "Defect in Non-mentioning of days of delay": "17.1",
  "Blanks NOT filed in affidavit": "5.5",
  "Memo of parties NOT filed": "15.1",
  "All annexure dates NOT matched index dates": "18.3",
  "All Case numbers NOT matched between annexures and index": "18.5",
  "Certificate NOT filed in support of SLP by AoR": "1.3",
  "Page number of annexures NOT mentioned in the list of dates": "2.6",
  "Memo of Appearance NOT filed properly": "8.1",
  "Application for exemption from filing an official translation mentioned but NOT present":
    "6.1",

  "SLP format is incorrect": "1.1",
  "Findings of high court NOT mentioned in SLP": "1.5",
  "Drawn by NOT mentioned": "1.7",
  "Date of filing NOT mentioned": "1.7",
  "List of Dates Absent": "2.1",
  "Office report on Limitation Absent": "2.5",
  "Description of annexures NOT mentioned in the list of dates": "2.7",
  "Description of annexures NOT mentioned in the index": "2.8",
  "Affidavit NOT filed": "5.3",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent":
    "5.2",
  "Deponent capacity Not Appicable": "5.4",
  "All order dates NOT present in list of dates and events": "N/A",
  "All annexure descriptions NOT matched index descriptions": "2.8",
};
const pageLevelDefects = [
  "Blurred Page",
  "Missing Page Number",
  "Signature Absent",
  "Hindi",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];

const pageLevelDefectsCode = {
  "Blurred Page": "3.2",
  "Missing Page Number": "3.5",
  "Signature Absent": "4.1",
  "NOT in OCR format": "N/A",
  Hindi: "6.1",
  Assamese: "6.1",
  Bengali: "6.1",
  Bodo: "6.1",
  Dogri: "6.1",
  Gujarati: "6.1",
  Kannada: "6.1",
  Kashmiri: "6.1",
  Konkani: "6.1",
  Maithili: "6.1",
  Malayalam: "6.1",
  Manipuri: "6.1",
  Marathi: "6.1",
  Nepali: "6.1",
  Odia: "6.1",
  Punjabi: "6.1",
  Sanskrit: "6.1",
  Santali: "6.1",
  Sindhi: "6.1",
  Tamil: "6.1",
  Telugu: "6.1",
  Urdu: "6.1",
};

const DefectTab = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [isEfilingModal, setIsEfilingModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  const contentRef = useRef(null);
  let serialNumber = 1;

  // ProgressBar States
  const [progress, setProgress] = useState(15);

  const {
    setSelectedDefectsPage,
    handleMetaFieldInputChange,
    handleRemoveClick,
    handleUpdate,
    handleRemarksChange,
    handleSelectDefectChange,
    handleSubmitMetaField,
    selectedDefectsPage,
    updateData,
    selectedData,
    setUpdateData,
    getSelectedData,
  } = useGetSelectedData({ id, setShowUpdateModal, setIsDisabled });

  const defectsCategory = updateData?.defectsCategory;
  const { selectedCourt } = updateData || {};

  const { filename } = updateData || {};

  //Messages For Loader
  const messages = [
    "Validating document",
    "Converting the pages to images",
    "Extracting text from the pages",
    "Checking for defects",
    "Defect detection(Dim Page, Missing Page no., etc.)",
    "Finalizing results",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const getDefectPdf = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      const res = await axios.get(
        `${REACT_APP_API_KEY}/getDefectPdf?filename=${id}`,
        {
          responseType: "blob", // This ensures the response is in blob format
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob); // Create a URL for the blob
      setPdfUrl(url);
      setIsPdfLoading(false);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    return false;
  }, [id]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isCompleted = await getDefectPdf();
      if (isCompleted) {
        clearInterval(intervalId); // Stop calling the function if status is "Completed"
      }
    }, 5000); // Call every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [getDefectPdf]);

  useEffect(() => {
    getDefectPdf();
  }, [id, getDefectPdf]);

  useEffect(() => {
    if (selectedData.length === 0) {
      let intervalId;
      if (currentMessageIndex < 5) {
        intervalId = setInterval(() => {
          setCurrentMessageIndex((prevIndex) => prevIndex + 1);
          setProgress(progress + 15);
        }, 10000); // 10 seconds
      }

      return () => clearInterval(intervalId);
    }
  }, [currentMessageIndex, progress, selectedData]);

  const handleAuditHistory = () => {
    navigate("/SelectedFileAnalysis/AuditHistory", { state: id });
  };

  const handleDownload = () => {
    const element = contentRef.current;
    const opt = {
      margin: [16, 6, 16, 6],
      filename: `NyaayAI_${filename}`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16, // or "smart", default is 16
      },
    };
    html2pdf().set(opt).from(element).save();
  };
  const {
    doc_level_defects = [],
    defects = [],
    uploadedAt,
    efilling_processed,
  } = updateData;

  const handleRouteEfiling = (id) => {
    const encodedData = { id: id };
    const navigateURL = `${REACT_APP_BASE_URL}/EfilingHistory?data=${JSON.stringify(
      encodedData
    )}`;
    window.open(navigateURL, "_self");
  };

  return (
    <div className="py-2 mx-6">
      <ToastContainer />
      <div className="flex justify-between">
        <div className="flex w-1/2 justify-between">
          <button onClick={() => navigate("/DefectAnalysis")}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          <h1 className="text-[#40566D] text-lg font-semibold">
            Defect Detection
          </h1>
          <h1 className="text-[#40566D] text-lg font-medium">{filename}</h1>
        </div>
        <div className="flex gap-5">
          {efilling_processed ? (
            <button
              onClick={() => handleRouteEfiling(id)}
              className="bg-[#5E48FC] px-4 items-center hover:border-[#D8E3FF] disabled:bg-[#F9FAFB] disabled:border-[#C6C7C9] disabled:text-[#A2A3A7] flex justify-center border border-[#4C6AF7] item-center py-1.5 rounded-lg text-sm text-[#FFFFFF]"
            >
              Show Previously Processed E-Filing
            </button>
          ) : null}
          <button
            onClick={() => setIsEfilingModal(true)}
            className="bg-[#5E48FC] px-4 items-center hover:border-[#D8E3FF] disabled:bg-[#F9FAFB] disabled:border-[#C6C7C9] disabled:text-[#A2A3A7] flex justify-center border border-[#4C6AF7] item-center py-1.5 rounded-lg text-sm text-[#FFFFFF]"
          >
            E-Filing Automation
          </button>
          <button className=" active:animate-bounce" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} color="#5E48FC" />
          </button>
        </div>
      </div>
      <hr className="sm:-mx-6 mt-2"></hr>

      <div className="sm:flex justify-between w-full my-4">
        <div className="sm:w-1/2 h-full">
          {isPdfLoading ? (
            <div className="mt-12 flex justify-center items-center">
              <Oval
                height={120}
                width={120}
                color="Indigo"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="Indigo"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : selectedData.length > 0 ? (
            <ViewDefectUploadedDocument pdfUrl={pdfUrl} />
          ) : (
            <div className="mt-12 flex justify-center items-center text-[#40566D] text-sm font-medium">
              Document in process. Hold on for sometime.
            </div>
          )}
        </div>

        <div className="overflow-y-auto max-h-screen sm:w-1/2 ps-2 sm:border-l sm:ml-2 sm:-mt-4">
          <div className="flex justify-between py-2 items-center">
            <div className="font-semibold text-[#40566D]">
              Digitization Result
            </div>

            <div className="sm:mt-0 sm:flex flex gap-5 flex-col sm:flex-row">
              {selectedData.length > 0 ? (
                <button
                  className="bg-[#5E48FC] hover:border-[#D8E3FF] items-center disabled:bg-[#F9FAFB] disabled:border-[#C6C7C9] disabled:text-[#A2A3A7] flex justify-center border border-[#4C6AF7] item-center py-1 rounded-lg text-sm text-[#FFFFFF] px-4"
                  // className="disabled:bg-[#6C849D1F] disabled:text-[#192839] px-4 border items-center border-[#C6C7C9] py-1 cursor-pointer rounded-lg"
                  onClick={() => handleAuditHistory()}
                >
                  Audit History
                </button>
              ) : null}
              <button
                onClick={() => setShowUpdateModal(true)}
                disabled={isDisabled}
                className="bg-[#5E48FC] hover:border-[#D8E3FF] items-center disabled:bg-[#F9FAFB] disabled:border-[#C6C7C9] disabled:text-[#A2A3A7] flex justify-center border border-[#4C6AF7] item-center py-1 rounded-lg text-sm text-[#FFFFFF] px-4"
              >
                Update
              </button>
            </div>
          </div>
          <hr></hr>
          <div className="py-4" style={{ display: "none" }}>
            <div ref={contentRef} className="text-[#1F2A37] text-sm">
              <div className="text-lg flex justify-between items-center mb-4">
                <span> Processed by Nyaay AI</span>
                <span>{formatDateTime(uploadedAt)}</span>
              </div>
              <div className="text-lg mb-4 flex justify-center">{filename}</div>
              <div className="text-lg mb-4">Document Level Defects</div>
              <div className="flex w-full uppercase bg-[#F9FAFB] pb-4 text-sm font-medium">
                <div className="w-[10%] px-1 flex justify-center items-center">
                  S.No.
                </div>
                <div className="w-[15%] px-1 flex justify-center items-center">
                  Default Code
                </div>
                <div className="w-[35%] px-1 flex justify-center items-center">
                  Default
                </div>
                <div className="w-[40%] px-1 flex justify-center items-center">
                  Default Simple (Nyaay AI)
                </div>
              </div>
              {doc_level_defects
                .filter((defects) =>
                  selectedCourt === "Delhi High Court"
                    ? documentLevelNyaayDefectForDHC.includes(
                        Object.values(defects)[0][0]
                      )
                    : defectStrings.includes(Object.values(defects)[0][0])
                )
                .map((defects, index) => {
                  const defectText = Object.values(defects)[0][0];
                  const defectCode =
                    selectedCourt === "Delhi High Court"
                      ? docLevelDefectCodesDHC[defectText] || "N/A"
                      : defectCodes[defectText] || "N/A"; // Assuming "N/A" if code not found
                  return (
                    <div
                      className="text-sm flex w-full border border-b-0 last:border-b"
                      key={index}
                    >
                      <div className="w-[10%] px-2 pb-4 border-r justify-center items-center flex">
                        {index + 1}
                      </div>
                      <div className="w-[15%] px-2 pb-4 items-center justify-center border-r flex">
                        {defectCode}
                      </div>
                      <div
                        className="pb-4 px-2 w-[35%] items-center border-r flex"
                        // style={{
                        //   wordWrap: "break-word",
                        //   wordBreak: "break-all",
                        // }}
                      >
                        {selectedCourt === "Delhi High Court"
                          ? DEFAULT_DOC_LEVEL_DEFECTS_DHC[defectText]
                          : DEFAULT_DOC_LEVEL[defectText]}
                      </div>
                      <div
                        // style={{
                        //   wordWrap: "break-word",
                        //   wordBreak: "break-all",
                        // }}
                        className="pb-4 px-2 w-[35%] items-center flex"
                      >
                        {defectText}
                      </div>
                    </div>
                  );
                })}
              <hr></hr>

              <div className="py-4">
                <div className="text-lg mb-4">Page Level Defects</div>
                <div className="flex w-full uppercase bg-[#F9FAFB] pb-4 justify-center items-center text-sm font-medium">
                  <div className="w-[10%] flex justify-center px-1">S.No.</div>
                  <div className="w-[10%] flex justify-center items-center px-1">
                    Default Code
                  </div>
                  <div className="w-[35%] flex justify-center items-center px-1">
                    Default
                  </div>
                  <div className="w-[35%] flex justify-center items-center px-1">
                    Default Simple (Nyaay AI)
                  </div>
                  <div className="w-[10%] flex justify-center items-center px-1">
                    Page No.
                  </div>
                </div>
                {defects.map((item, index) => {
                  return item?.defectFounds.map((defect, defectIndex) => {
                    const isDefect =
                      selectedCourt === "Delhi High Court"
                        ? PAGE_LEVEL_DEFECT_STRING_DHC.includes(defect)
                        : pageLevelDefects.includes(defect);
                    if (!isDefect) {
                      return null;
                    }
                    const defectCode =
                      selectedCourt === "Delhi High Court"
                        ? pageLevelDefectsCodeDHC[defect] || "N/A"
                        : pageLevelDefectsCode[defect] || "N/A"; // Assuming "N/A" if code not found
                    const currentSerialNumber = serialNumber++;
                    return (
                      <div
                        className="text-sm flex w-full border border-b-0 last:border-b"
                        key={`${index}-${defectIndex}`}
                      >
                        <div className="w-[10%] px-2 pb-4 border-r items-center flex justify-center">
                          {currentSerialNumber}
                        </div>
                        <div className="w-[10%] px-2 pb-4 border-r items-center flex justify-center">
                          {defectCode}
                        </div>
                        <div
                          className="pb-4 px-2 border-r w-[35%] items-center flex"
                          // style={{
                          //   wordWrap: "break-word",
                          //   wordBreak: "break-all",
                          // }}
                        >
                          {selectedCourt === "Delhi High Court"
                            ? DEFAULT_PAGE_LEVEL_DHC[defect]
                            : DEFAULT_PAGE_LEVEL[defect]}
                        </div>
                        <div className="pb-4 px-2 border-r w-[35%] items-center flex">
                          {VERNACULAR_LANGUAGE.includes(defect)
                            ? `Vernacular Language - ${defect}`
                            : defect}
                        </div>
                        <div className="pb-4  flex items-center justify-center w-[10%]">
                          {item.defectPage}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>
          {/* Choosen Defects Category */}
          {defectsCategory?.length > 0 ? (
            <div>
              <div className="flex justify-center items-center font-bold">
                Selected Defects Category
              </div>
              <div className="mt-1 flex flex-wrap justify-center items-center mb-2">
                {defectsCategory.length > 0
                  ? defectsCategory.map((defect) => (
                      <div
                        key={defect.id}
                        className="bg-red-500 p-1 rounded m-1 text-white"
                      >
                        {defect}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          ) : null}
          <div className="flex flex-wrap items-center justify-center">
            {selectedData?.length > 0 ? (
              <DefectedResults
                data={selectedData}
                setSelectedDefectsPage={setSelectedDefectsPage}
                handleRemoveClick={handleRemoveClick}
                selectedDefectsPage={selectedDefectsPage}
                handleSelectDefectChange={handleSelectDefectChange}
                handleRemarksChange={handleRemarksChange}
                handleSubmitMetaField={handleSubmitMetaField}
                handleMetaFieldInputChange={handleMetaFieldInputChange}
                updateData={updateData}
                setUpdateData={setUpdateData}
                id={id}
                filename={filename}
                getSelectedData={getSelectedData}
                setIsDisabled={setIsDisabled}
              />
            ) : (
              <DefectedResultsLoader
                messages={messages}
                currentMessageIndex={currentMessageIndex}
                progress={progress}
              />
            )}
          </div>
        </div>
      </div>
      {showUpdateModal ? (
        <UpdateModal
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          handleUpdate={handleUpdate}
          id={id}
        />
      ) : null}
      {isEfilingModal ? (
        <EfilingConfirmationModal
          isEfilingModal={isEfilingModal}
          setIsEfilingModal={setIsEfilingModal}
          id={id}
        />
      ) : null}
    </div>
  );
};

export default DefectTab;
