import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MetaDataField = ({
  handleSubmitMetaField,
  handleMetaFieldInputChange,
  updateData,
}) => {
  const [showMetaField, setShowMetaField] = useState(false);
  const { metaData } = updateData || {};
  const handleSaveMetaField = (e) => {
    setShowMetaField(false);
    handleSubmitMetaField(e);
    toast.info("To Save it permanently please Click on Update Button", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };
  return (
    <div
      className="bg-white shadow-md mx-1 p-2 rounded-md my-4"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex items-center bg-white justify-between">
        <h1 className="text-[#40566D] font-semibold">Meta data Field</h1>

        <button
          className="border border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center px-1 shadow-md"
          onClick={() => setShowMetaField((prev) => !prev)}
        >
          {showMetaField ? "Collapse All" : "View All"}
          <div className="w-5">
            {showMetaField ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </div>
        </button>
      </div>
      {showMetaField ? (
        <div>
          <form onSubmit={(e) => handleSaveMetaField(e)}>
            <div className="mb-3">
              <label className="text-sm text-[#40566D] font-semibold">
                Court Name
              </label>
              <input
                type="text"
                name="courtName"
                defaultValue={metaData?.courtName || ""}
                onChange={handleMetaFieldInputChange}
                className="w-full p-1 border font-medium py-2 px-2 text-sm disabled:bg-[#6C849D52] text-[#40566D]  rounded-md mt-1 focus:outline-none focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-3">
              <label className="text-sm text-[#40566D] font-semibold">
                Judge
              </label>
              <input
                type="text"
                name="judge"
                defaultValue={metaData?.judge || ""}
                onChange={handleMetaFieldInputChange}
                className="w-full p-1 border font-medium py-2 px-2 text-sm disabled:bg-[#6C849D52] text-[#40566D]  rounded-md mt-1 focus:outline-none focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-3">
              <label className="text-sm text-[#40566D] font-semibold">
                Petitioner
              </label>
              <input
                name="petitioner"
                type="text"
                defaultValue={metaData?.petitioner || ""}
                onChange={handleMetaFieldInputChange}
                className="w-full p-1 border font-medium py-2 px-2 text-sm disabled:bg-[#6C849D52] text-[#40566D]  rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="text-sm text-[#40566D] font-semibold">
                Respondent
              </label>
              <input
                name="respondent"
                type="text"
                defaultValue={metaData?.respondent || ""}
                onChange={handleMetaFieldInputChange}
                className="w-full p-1 border font-medium py-2 px-2 text-sm disabled:bg-[#6C849D52] text-[#40566D]  rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="text-sm text-[#40566D] font-semibold">
                Attorney
              </label>
              <input
                name="attorney"
                type="text"
                defaultValue={metaData?.attorney || ""}
                onChange={handleMetaFieldInputChange}
                className="w-full p-1 border font-medium py-2 px-2 text-sm disabled:bg-[#6C849D52] text-[#40566D]  rounded-md mt-1 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <ToastContainer />
            <button
              type="submit"
              className="w-full mb-2 bg-[#5E48FC] text-white p-1.5 rounded-lg hover:bg-indigo-700 focus:outline-none focus:shadow-outline-indigo"
            >
              Save
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default MetaDataField;
