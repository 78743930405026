import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { REACT_APP_API_KEY } = process.env;
function formatData(response) {
  const formattedData = [];
  response?.forEach((defect) => {
    const defectPage = defect.pageNo || defect.page_no;

    const existingEntry = formattedData.find(
      (entry) => entry.defectPage === defectPage
    );
    if (existingEntry) {
      existingEntry.defectFounds.push(defect.category);
    } else {
      formattedData.push({
        defectPage: defectPage,
        defectFounds: [defect.category],
      });
    }
  });

  formattedData.sort((a, b) => a.defectPage - b.defectPage);
  return formattedData;
}

const useGetSelectedData = ({ id, setShowUpdateModal, setIsDisabled }) => {
  const [selectedData, setSelectedData] = useState([]);
  const [updateData, setUpdateData] = useState({});

  // Initial Defects State
  const [initialData, setInitialData] = useState([]);

  // Defects Added By User
  const [addedDefects, setAddedDefects] = useState([]);
  const [deletedDefects, setDeletedDefects] = useState([]);

  const userDetails = JSON.parse(localStorage.getItem("userDetails")).userData;
  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getSelectedData = useCallback(async () => {
    // const desired_data = [
    //   "blur",
    //   "Signature Missing",
    //   "Missing Page Number",
    //   "vern_lang",
    // ];
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getDefectDataById`,
        {
          params: {
            id: id,
          },
          // withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { _source } = response.data || {};

      if (_source.status === "In Progress") {
        // Fetching the data after every 5 seconds
        setTimeout(getSelectedData, 5000);
      } else {
        const formattedDefects =
          response?.data?._source?.defects[0]?.defectPage !== undefined
            ? response?.data?._source?.defects
            : formatData(response?.data?._source?.defects);

        // console.log("Nirupam ", formattedDefects[0].defectFounds);

        // const desired_data = [
        //   "blur",
        //   "Signature Missing",
        //   "Missing Page Number",
        //   "vern_lang",
        // ];
        // const newdefectsarray = [];
        for (let i = 0; i < formattedDefects.length; i++) {
          const defectsarray = formattedDefects[i].defectFounds;
          // console.log("Old Defects ", formattedDefects[i].defectFounds);
          const newdefectsarray = [];
          for (const defect of defectsarray) {
            // console.log("defects are ", defect);
            if (
              defect === "Assamese" ||
              defect === "Bengali" ||
              defect === "Bodo" ||
              defect === "Dogri" ||
              defect === "Gujarati" ||
              defect === "Hindi" ||
              defect === "Kannada" ||
              defect === "Kashmiri" ||
              defect === "Konkani" ||
              defect === "Maithili" ||
              defect === "Malayalam" ||
              defect === "Manipuri" ||
              defect === "Marathi" ||
              defect === "Nepali" ||
              defect === "Odia" ||
              defect === "Punjabi" ||
              defect === "Sanskrit" ||
              defect === "Santali" ||
              defect === "Sindhi" ||
              defect === "Tamil" ||
              defect === "Telugu" ||
              defect === "Urdu"
            ) {
              newdefectsarray.push(defect);
            } else if (defect.startsWith("Signature Absent")) {
              newdefectsarray.push(defect);
            } else if (defect === "Missing Page Number") {
              newdefectsarray.push(defect);
              // } else if (defect.startsWith("vern_lang")) {
              //   newdefectsarray.push(defect);
            } else if (defect === "Blurred Page") {
              newdefectsarray.push(defect);
            } else if (defect === "NOT in OCR format") {
              newdefectsarray.push(defect);
            } else if (defect === "Filed in OCR format") {
              newdefectsarray.push(defect);
            } else if (defect === "Signature Present") {
              newdefectsarray.push(defect);
            } else if (defect === "Ink Mark") {
              newdefectsarray.push(defect);
            } else if (!isNaN(parseInt(defect))) {
              newdefectsarray.push(defect);
            }
          }
          formattedDefects[i].defectFounds = newdefectsarray;
          // console.log("new Defects ", formattedDefects[i].defectFounds);
          // console.log("New defects are ", newdefectsarray);
        }

        //Set initial data
        setInitialData(formattedDefects);
        setUpdateData({ ..._source, defects: formattedDefects });
        setSelectedData(formattedDefects);
        setIsDisabled(true);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        return;
      }
      toast.error(error || "Something went wrong!");
    }
    // eslint-disable-next-line
  }, [id, token]);

  useEffect(() => {
    getSelectedData();
  }, [id, getSelectedData]);

  const [selectedDefectsPage, setSelectedDefectsPage] = useState(null);

  const { metaData: metaDataField } = updateData || {};
  const { courtName, judge, petitioner, respondent, attorney } =
    metaDataField || {};
  const [metaData, setMetaData] = useState({
    courtName: courtName || "",
    judge: judge || "",
    petitioner: petitioner || "",
    respondent: respondent || "",
    attorney: attorney || "",
  });

  useEffect(() => {
    setMetaData({
      courtName: courtName || "",
      judge: judge || "",
      petitioner: petitioner || "",
      respondent: respondent || "",
      attorney: attorney || "",
    });
  }, [courtName, judge, petitioner, respondent, attorney]);

  const handleMetaFieldInputChange = (e) => {
    const { name, value } = e.target;
    setMetaData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitMetaField = (e) => {
    e.preventDefault();
    console.log(metaData, "metadata");
    setUpdateData((prev) => ({ ...prev, metaData: metaData }));
  };

  const handleSelectDefectChange = (e) => {
    const selectedValue = e.target.value;

    // Adding User selected defects
    const existingDefectIndex = addedDefects.findIndex(
      (item) => item.defectPage === selectedDefectsPage
    );
    if (existingDefectIndex !== -1) {
      const updatedDefects = [...addedDefects];
      updatedDefects[existingDefectIndex].category.push(selectedValue);
      setAddedDefects(updatedDefects);
    } else {
      const updatedDefects = [
        ...addedDefects,
        { defectPage: selectedDefectsPage, category: [selectedValue] },
      ];
      setAddedDefects(updatedDefects);
    }

    const newData = selectedData.map((item) => {
      if (item.defectPage === selectedDefectsPage) {
        return {
          ...item,
          defectFounds: [...item.defectFounds, selectedValue],
        };
      }
      return item;
    });
    setSelectedData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleRemarksChange = (e) => {
    const selectedValue = e.target.value;
    const newData = selectedData.map((item) => {
      if (item.defectPage === selectedDefectsPage) {
        return {
          ...item,
          remarks: selectedValue,
        };
      }
      return item;
    });
    setSelectedData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleRemoveClick = (defectedItem, defectedPage) => {
    // Removing User selected defects
    initialData.forEach((item) => {
      if (item.defectPage === defectedPage) {
        const selectedDefectsPage = item.defectFounds;
        if (selectedDefectsPage.includes(defectedItem)) {
          const existingDefectIndex = deletedDefects.findIndex(
            (item) => item.defectPage === defectedPage
          );

          if (existingDefectIndex !== -1) {
            const updatedDefects = [...deletedDefects];
            updatedDefects[existingDefectIndex].category.push(defectedItem);
            setDeletedDefects(updatedDefects);
          } else {
            const updatedDefects = [
              ...deletedDefects,
              { defectPage: defectedPage, category: [defectedItem] },
            ];
            setDeletedDefects(updatedDefects);
          }
        } else {
          const newAddedDefect = addedDefects.filter((item) => {
            if (item.defectPage === defectedPage) {
              const newDefectsFound = item.category.filter(
                (defect) => defect !== defectedItem
              );
              if (newDefectsFound.length > 0) {
                item.category = newDefectsFound;
                return true;
              }
              return false;
            }
            return true;
          });

          setAddedDefects(newAddedDefect);
        }
      }
    });

    const newData = [...selectedData];
    newData.forEach((item) => {
      if (item.defectPage === defectedPage) {
        const newDefectsFound = item.defectFounds.filter(
          (defect) => defect !== defectedItem
        );
        item.defectFounds = newDefectsFound;
      }
    });
    setSelectedData(newData);
    setUpdateData((prev) => ({ ...prev, defects: newData }));
  };

  const handleUpdate = async (id) => {
    //Audit History Data
    const dataToSend = {
      doc_id: id,
      email: userDetails.email,
      username: userDetails.name,
      filename: updateData.filename,
      defects: [
        {
          type: "Add",
          defectsFound: addedDefects,
        },
        {
          type: "Delete",
          defectsFound: deletedDefects,
        },
      ],
    };

    try {
      const response = await fetch(`${REACT_APP_API_KEY}/updateDefects/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // credentials: "include",
        body: JSON.stringify(updateData),
      });
      await response.json();
      toast.success("Data is successfully updated", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      getSelectedData();
      setAddedDefects([]);
      setDeletedDefects([]);

      setShowUpdateModal(false);
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        toast.error("Invalid Token. Please Login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        return;
      }
      toast.error("Opps somthing went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }

    //Audit History
    if (addedDefects.length > 0 || deletedDefects.length > 0) {
      try {
        const response = await fetch(`${REACT_APP_API_KEY}/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // credentials: "include",
          body: JSON.stringify(dataToSend),
        });
        await response.json();
        console.log(response);
      } catch (error) {
        console.log(error, "error");
        if (error.response.status === 401) {
          toast.error("Invalid Token. Please Login!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      }
    }
  };
  return {
    // formatData,
    setSelectedDefectsPage,
    handleMetaFieldInputChange,
    handleRemoveClick,
    handleUpdate,
    handleRemarksChange,
    handleSelectDefectChange,
    handleSubmitMetaField,
    selectedData,
    selectedDefectsPage,
    updateData,
    setUpdateData,
    getSelectedData,
  };
};

export default useGetSelectedData;
