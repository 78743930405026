import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import useGetExistingEfiling from "./useGetExistingEfiling";
import Dskeleton from "../../common-components/Dskeleton";
import Pagination from "../../common-components/Pagination";
import { Arrow } from "../../common-components/Arrow";
import RenderTooltipComponent from "../../common-components/RenderTooltipComponent";
import { ToastContainer } from "react-toastify";
const dateFormat = "dd/MM/yyyy";

const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY | HH:mm");
};
const ExistingEfilingDoc = ({
  existingEfilingData = [],
  existingDataLoading,
  setEistingDataLoading,
  filters,
  setFilters,
  handleFileNameClick,
  currentPage,
  setCurrentPage,
  setOrder,
  setSortingColumn,
  sortingColumn,
  order,
}) => {
  // const {
  //   existingEfilingData = [],
  //   existingDataLoading,
  //   setEistingDataLoading,
  //   filters,
  //   setFilters,
  //   handleFileNameClick,
  //   currentPage,
  //   setCurrentPage,
  //   setOrder,
  //   setSortingColumn,
  //   sortingColumn,
  //   order,
  // } = useGetExistingEfiling();

  const { searchInput = "", dates } = filters || {};
  const [startDate, endDate] = dates || [];
  const { searchedData = [], totalRecords, totalPages } = existingEfilingData;

  const handleDateChange = (dates) => {
    setEistingDataLoading(true);
    setFilters((prev) => ({ ...prev, dates: dates || undefined }));
  };

  const handleSearchTermChange = (event) => {
    setEistingDataLoading(true);
    setFilters((prev) => ({
      ...prev,
      searchInput: event.target.value || undefined,
    }));
  };

  const handleColumnHeaderClick = (headerColumn) => {
    if (sortingColumn === headerColumn) {
      setOrder(order === "desc" ? "asc" : "desc");
    } else setOrder("asc");
    setEistingDataLoading(true);
    setSortingColumn(headerColumn);
  };

  return (
    <div className="my-4 mx-2 flex flex-col">
      <ToastContainer />
      <div className="flex items-center justify-between mb-4">
        <div className="w-3/5">
          <input
            type="search"
            placeholder="Search by File / Email / Court / Petitioner / Attorney / Court / Respondent Name"
            value={searchInput}
            onChange={handleSearchTermChange}
            required
            className="w-full font-normal text-sm p-1.5 border border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="ml-4">
          <DatePicker
            id="dateRange"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable
            showMonthDropdown
            showYearDropdown
            dateFormat={dateFormat}
            placeholderText="Select Date Range"
            className="p-1.5 border text-sm  border-[#B1C1D2] rounded focus:outline-none focus:border-indigo-500"
          />
        </div>
      </div>
      <div className="bg-white max-h-screen relative flex flex-col">
        <div className="flex-grow overflow-x-auto">
          <table className="table-fixed border  w-full">
            <thead>
              <tr className="bg-[#6C849D1F] text-[#192839] font-medium text-sm">
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="font-semibold pr-1 cursor-pointer"
                      onClick={() => handleColumnHeaderClick("filename")}
                    >
                      Filename
                    </span>
                    <Arrow
                      onClick={() => handleColumnHeaderClick("filename")}
                      headerColumn="filename"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="font-semibold cursor-pointer">Status</span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="cursor-pointer pr-1 font-semibold"
                      onClick={() => handleColumnHeaderClick("uploadedAt")}
                    >
                      Uploaded Date
                    </span>
                    <Arrow
                      onClick={() => handleColumnHeaderClick("uploadedAt")}
                      headerColumn="uploadedAt"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>

                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span className="cursor-pointer pr-1 font-semibold">
                      Uploaded By
                    </span>
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="cursor-pointer pr-1 font-semibold"
                      onClick={() => handleColumnHeaderClick("CourtType")}
                    >
                      Court Name
                    </span>
                    <Arrow
                      onClick={() => handleColumnHeaderClick("CourtType")}
                      headerColumn="CourtType"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>
                <th className="py-2 px-4 text-left">
                  <div className="flex items-center">
                    <span
                      className="cursor-pointer pr-1 font-semibold"
                      onClick={() =>
                        handleColumnHeaderClick("MainPetitionerName")
                      }
                    >
                      Petitioner Name
                    </span>
                    <Arrow
                      onClick={() =>
                        handleColumnHeaderClick("MainPetitionerName")
                      }
                      headerColumn="MainPetitionerName"
                      order={order}
                      activeColumn={sortingColumn}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {existingDataLoading ? (
                <Dskeleton columns={4} />
              ) : (
                <>
                  {searchedData?.length > 0 ? (
                    searchedData?.map((data, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-200 bg-white border-b border-grey-100"
                      >
                        <td className="py-2 px-4 font-normal text-sm">
                          <button
                            className="text-[#2950DA] hover:underline"
                            onClick={() => handleFileNameClick(data?._id)}
                          >
                            <RenderTooltipComponent
                              content={data?._source?.filename}
                              maxLength={20}
                              maxWidth={600}
                            />
                          </button>
                        </td>
                        <td className="py-2 ps-4 font-normal text-sm">
                          <span
                            className={
                              data._source["status"] === "In Progress"
                                ? "py-0.5 px-1.5 bg-[#D92D2017] rounded-xl text-[#D92D20]"
                                : "py-0.5 px-1.5 bg-[#00A25117] text-[#008743] rounded-xl"
                            }
                          >
                            {data._source["status"]}
                          </span>
                        </td>
                        <td className="py-2 ps-4 text-[#192839] font-normal text-sm">
                          {formatDateTime(data?._source?.uploadedAt) || "--"}
                        </td>
                        <td className="py-2 ps-4  text-[#192839] font-normal text-sm">
                          <RenderTooltipComponent
                            content={data?._source?.uploadedBy}
                            maxLength={20}
                            maxWidth={600}
                          />
                        </td>
                        <td className="py-2 ps-4  text-[#192839] font-normal text-sm">
                          <RenderTooltipComponent
                            content={data?._source?.CourtType}
                            maxLength={20}
                            maxWidth={600}
                          />
                        </td>
                        <td className="py-2 ps-4  text-[#192839] font-normal text-sm">
                          <RenderTooltipComponent
                            content={data?._source?.MainPetitionerName}
                            maxLength={19}
                            maxWidth={600}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="bg-white p-3">
                        <h1 className="rounded shadow-md p-3 items-center text-center">
                          No Results Found
                        </h1>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="self-end p-4">
          <Pagination
            setIsLoading={setEistingDataLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalRecords}
            totalPages={totalPages}
            pageSize={50}
          />
        </div>
      </div>
    </div>
  );
};

export default ExistingEfilingDoc;
