import React from "react";
import MetaDataField from "./MetaDataField";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoclabelDefect from "./DoclabelDefect";
import PageLevelDefects from "./PageLevelDefect"; // Import the new component
import UserComments from "./user-comments/UserComments";

const DefectedResults = ({
  data = [],
  setSelectedDefectsPage,
  handleRemoveClick,
  selectedDefectsPage,
  handleSelectDefectChange,
  handleRemarksChange,
  handleSubmitMetaField,
  handleMetaFieldInputChange,
  updateData,
  setUpdateData,
  id,
  getSelectedData,
  setIsDisabled,
}) => {
  return (
    <div className="w-full">
      <MetaDataField
        handleSubmitMetaField={handleSubmitMetaField}
        handleMetaFieldInputChange={handleMetaFieldInputChange}
        updateData={updateData}
      />
      <DoclabelDefect updateData={updateData} setUpdateData={setUpdateData} />
      <PageLevelDefects
        data={data}
        setSelectedDefectsPage={setSelectedDefectsPage}
        handleRemoveClick={handleRemoveClick}
        selectedDefectsPage={selectedDefectsPage}
        handleSelectDefectChange={handleSelectDefectChange}
        handleRemarksChange={handleRemarksChange}
        updateData={updateData}
        id={id}
        getSelectedData={getSelectedData}
        setIsDisabled={setIsDisabled}
      />
      <UserComments data={updateData} />
      <ToastContainer />
    </div>
  );
};

export default DefectedResults;
