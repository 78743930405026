import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { formatDateTime } from "../DefectTab";
import moment from "moment";

const UserComments = ({ data }) => {
  const { comments = [] } = data || {};
  const [showUserComments, setShowUserComments] = useState(false);
  console.log(comments, "updatd");
  return (
    <div
      className="bg-white shadow my-2 mx-1 rounded p-2"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-[#40566D] font-semibold">User Comments</h1>
        <button
          className="border gap-1 hover:border-2 h-8 border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center px-2 shadow-md"
          onClick={() => setShowUserComments((prev) => !prev)}
        >
          {showUserComments ? "Collapse All" : "View All"}
          <FontAwesomeIcon icon={showUserComments ? faAngleUp : faAngleDown} />
        </button>
      </div>
      {showUserComments ? (
        <div className="my-2 border text-sm rounded-lg">
          <div className="flex text-sm bg-gray-200 py-2 ps-2">
            <div className="w-[80%]">Comments</div>
            <div className="w-[20%]">Commented At</div>
          </div>
          {comments.map((item, index) => {
            return (
              <div className="flex py-1  border-b" key={index}>
                <div className="flex flex-wrap ms-2 last:border-none w-[74%]">
                  {item.commentText}
                </div>
                <div className="w-[26%]">
                  {formatDateTime(item.insertTime)} |{" "}
                  {moment(item.insertTime).format("HH:MM")}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default UserComments;
