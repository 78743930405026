import React from "react";

function ViewDefectUploadedDocument({ pdfUrl }) {
    return (
        <div className="flex justify-center items-center">
            <iframe title="PDF Viewer" src={pdfUrl} className="h-screen w-full" />
        </div>
    );
}

export default ViewDefectUploadedDocument;
